@import 'mixins.scss';

.outer {
  background: $dark-gray-6;
}

.bottom {
  background: $black;
}

.container {
  max-width: rem(1280);
  padding: 0 rem(30);
  margin: 0 auto;

  a {
    &:hover {
      color: $gray-5;
    }
  }
}

.footerFullWidth {
  max-width: none;
}

.logo {
  line-height: 1;
  white-space: nowrap;

  &__letter {
    float: left;
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin-right: 0.35714rem;
    font-weight: 700;
    font-size: 1.5rem;
    color: $white;
    border: 4px solid $white;
    padding-bottom: rem(2);
  }

  &__name {
    font-weight: 900;
    color: $white;
    font-size: 21px;
    margin-left: rem(40);
  }

  &__descr {
    color: $gray-4;
    margin-left: rem(40);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.input,
.input:hover,
.input:focus {
  background: $dark-gray-4;
  border-color: $dark-gray-4;
  color: $dark-gray-1;
}
